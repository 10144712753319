import $ from "jquery";
import Cookies from "js-cookie";
import pAll from "p-all";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/fixed";
import { ShippingProduct, ThuleBuyBtnData } from "./types/global.js";
import { initBasket } from "./v3/basket.js";
import { initCheckout } from "./v3/checkout.js";
import { getShippingProducts, initShipping } from "./v3/shipping.js";
import { getShippingPrices } from "./v3/shippingApi.getShippingPrices";
import { getDeliveryCountry, getSessionId, getSessionValues } from "./v3/shopFunctions.js";
import { getState } from "./v3/store.js";

(async function () {
  const cookieId = getSessionId();

  Cookies.set("ds_cookie_id", cookieId, { expires: 1 / 48 });

  console.log({ cookieId });
  // if (cookieId === undefined) {
  //   Cookies.set("ds_cookie_id", nanoid(), { expires: 1 / 48 });
  // } else {
  // }

  const state = getState();
  window.getState = getState;

  const [sessionValues] = await pAll([getSessionValues, refreshBasketProducts]);

  // @ts-ignore
  const refreshBasketInterval = setIntervalAsync(refreshBasketProducts, 10000);

  const removeTimeout = setTimeout(async () => {
    await clearIntervalAsync(refreshBasketInterval);
  }, 10000 * 5);

  if (getSessionId() !== state.sessionId()) {
    state.resetState();
  }

  // setInterval(async () => {
  //   if (state.sessionId() !== (getSessionId())) {
  //     state.resetState();
  //   }
  // }, 300000);

  state.setSession(sessionValues);

  window.getSessionId = getSessionId;
  window.getDeliveryCountry = getDeliveryCountry;

  await initShipping();

  if ($("body").hasClass("webshop-showbasket")) {
    initBasket();
  }

  $('input[name="BuyReturnUrl"]').each((_, e) => {
    $(e).val((_, currentReturnUrl) =>
      currentReturnUrl.includes("/shop/search")
        ? CurrencyReturnUrl.includes("?")
          ? `${CurrencyReturnUrl}&RefreshAllPrices=1`
          : `${CurrencyReturnUrl}?RefreshAllPrices=1`
        : currentReturnUrl.includes("?")
        ? `${currentReturnUrl}&RefreshAllPrices=1`
        : `${currentReturnUrl}?RefreshAllPrices=1`
    );
  });
  $<HTMLFormElement>('form[action="/shop/basket.asp"]').each((_, form) => {
    $(form).attr("action", `${$(form).attr("action")}?RefreshAllPrices=1`);

    $(form).on("submit", (event, msg) => {
      if (msg === undefined || msg !== "buy") {
        event.preventDefault();
      } else {
        form.submit();
      }
    });

    $(form)
      .find('input[type="submit"], input.BuyButton_ProductList')
      .off("click")
      .on("click", async (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        const buyProductResult = BuyProduct(form, "1", "0", "False");

        if (buyProductResult === false) return;

        const $form = $<HTMLFormElement>(form);

        localStorage.removeItem("ajaxAddedProduct");

        const productImage = $("body").hasClass("webshop-productinfo")
          ? $form.find('.imagescontainer .images img[itemprop="image"]').first().attr("src") ?? ""
          : $form.find(".image img").first().attr("src") ?? "";

        localStorage.setItem("lastAddedImage", productImage);

        const productName = $("body").hasClass("webshop-productinfo")
          ? $('h1 span[itemprop="name"]').text()
          : $form.find(".name a").text();

        localStorage.setItem("lastAddedName", productName);

        const productUrl = $("body").hasClass("webshop-productinfo")
          ? location.pathname
          : CurrencyReturnUrl.includes("?")
          ? `${CurrencyReturnUrl}&RefreshAllPrices=1`
          : `${CurrencyReturnUrl}?RefreshAllPrices=1`;

        localStorage.setItem("lastAddedUrl", productUrl);

        const productNumber = $form.find('input[name="ProductID"]').val()?.toString();

        const quantityVal = $form.find("input#amount").val();

        const quantity =
          quantityVal && typeof quantityVal === "string"
            ? isNaN(parseInt(quantityVal))
              ? 1
              : parseInt(quantityVal)
            : 1;

        if (productNumber === undefined) return;

        const extraProducts = $(".extraProduct.active")
          .toArray()
          .map((ep) => {
            const $ep = $(ep);

            const extraProductQuantityVal = $ep.find("input#amount").val();

            const extraProductQuantity =
              extraProductQuantityVal && typeof extraProductQuantityVal === "string"
                ? isNaN(parseInt(extraProductQuantityVal))
                  ? 1
                  : parseInt(extraProductQuantityVal)
                : 1;

            return {
              productNumber: $ep.find(".name").data("id").toString(),
              qty: extraProductQuantity,
            };
          });

        const { newProducts, existingProducts } = extraProducts.concat({ productNumber, qty: quantity }).reduce<{
          newProducts: Array<{ productNumber: string; qty: number }>;
          existingProducts: Array<{ productNumber: string; qty: number }>;
        }>(
          (acc, cur) => {
            const tmp = basketProducts.find((bp) => bp.productNumber === cur.productNumber);

            if (tmp) {
              acc.existingProducts = [
                ...acc.existingProducts,
                {
                  productNumber: cur.productNumber,
                  qty: cur.qty + tmp.quantity,
                },
              ];

              return acc;
            }

            acc.newProducts = [...acc.newProducts, { productNumber: cur.productNumber, qty: cur.qty }];

            return acc;
          },
          { newProducts: [], existingProducts: [] }
        );

        const shippingProducts = (await getShippingProducts()).map<ShippingProduct>((p) => {
          const existingProduct = existingProducts.find((p) => p.productNumber === p.productNumber);

          return {
            productNumber: p.productNumber,
            qty: existingProduct ? existingProduct.qty + 1 : p.qty,
          };
        });

        if (newProducts.length > 0) {
          shippingProducts.push(...newProducts);
        }

        const returnBuyAdded = $("body").hasClass("webshop-productinfo")
          ? $form.find("#returnBuy").length > 0 &&
            $form.find("#returnBuy").is(":checked") &&
            basketProducts.some((p) => p.productNumber === "retur60") === false
          : $form.find("input.returnBuy").length > 0 &&
            $form.find("input.returnBuy").is(":checked") &&
            basketProducts.some((p) => p.productNumber === "retur60") === false;

        const workshopAdded = $("body").hasClass("webshop-productinfo")
          ? $form.find("#workshopAdd").length > 0 &&
            $form.find("#workshopAdd").is(":checked") &&
            basketProducts.some((p) => p.productNumber === "workshop") === false
          : $form.find("input.workshopAdd").length > 0 &&
            $form.find("input.workshopAdd").is(":checked") &&
            basketProducts.some((p) => p.productNumber === "workshop") === false;

        // let extrasURL =
        //   returnBuyAdded && workshopAdded
        //     ? "/shop/showbasket.html?AddMultiple=1&ProductID=|retur60|workshop&Amount=|1|1"
        //     : returnBuyAdded
        //     ? "/shop/showbasket.html?AddMultiple=1&ProductID=|retur60&Amount=|1"
        //     : workshopAdded
        //     ? "/shop/showbasket.html?AddMultiple=1&ProductID=|workshop&Amount=|1"
        //     : "";

        const extrasParamsObj = {
          AddMultiple: "1",
          ProductID: "",
          Amount: "",
        };

        if (returnBuyAdded) {
          extrasParamsObj.ProductID += "|retur60";
          extrasParamsObj.Amount += "|1";
        }

        if (workshopAdded) {
          extrasParamsObj.ProductID += "|workshop";
          extrasParamsObj.Amount += "|1";
        }

        if (extraProducts.length > 0) {
          extraProducts.forEach((ep) => {
            extrasParamsObj.ProductID += `|${ep.productNumber}`;
            extrasParamsObj.Amount += `|${ep.qty}`;
          });
        }

        const extrasURL = extrasParamsObj.ProductID !== "" ? `/shop/showbasket.html?${$.param(extrasParamsObj)}` : "";

        const promises = [];

        if (extrasURL !== "") {
          promises.push(() => fetch(extrasURL));
        }

        if (shippingProducts.length > 0) {
          promises.push(async () => {
            const shipping = await getShippingPrices({
              sessionId: getSessionId(),
              products: shippingProducts,
              currency: siteCurrency,
              deliveryCountry: await getDeliveryCountry(),
              shippingName: siteShippingName,
            });
            state.setShipping(shipping);

            return shipping;
          });
        }

        await pAll(promises);

        $form.trigger("submit", ["buy"]);
      });
  });

  $(".CustomersAlsoBought_Custom_DIV .product").each((_, e) => {
    const $product = $(e);

    const productNumber = $product.data("id").toString();
    const image = $product.data("image").toString();
    const name = $product.data("name").toString();
    const url = $product.data("url").toString();

    const $buyButton = $product.find('input[type="submit"]');

    $buyButton.removeAttr("onclick"); // TODO: Remove in the DD template instead

    $buyButton.on("click", async (event) => {
      event.preventDefault();

      localStorage.removeItem("ajaxAddedProduct");
      localStorage.setItem("lastAddedImage", image);
      localStorage.setItem("lastAddedName", name);
      localStorage.setItem("lastAddedUrl", url);

      const existingProduct = basketProducts.find((bp) => bp.productNumber === productNumber);

      const shippingProducts = basketProducts
        .filter(
          (p) =>
            p.productNumber.toLowerCase() !== "retur60" &&
            p.productNumber.toLowerCase() !== "workshop" &&
            p.productNumber.toLowerCase() !== "tuv" &&
            p.productNumber.toLowerCase().startsWith("fragt-") === false
        )
        .map((product) => {
          return {
            productNumber: product.productNumber,
            qty:
              existingProduct?.productNumber === product.productNumber
                ? existingProduct?.quantity + 1
                : product.quantity,
          };
        });

      if (existingProduct === undefined) {
        shippingProducts.push({
          productNumber: productNumber,
          qty: 1,
        });
      }

      BuyRelatedProductCustom(productNumber, "1", "0");
    });
  });

  const path = window.location.pathname;
  const query = new URLSearchParams(window.location.search);

  if (query.has("RefreshAllPrices")) {
    query.delete("RefreshAllPrices");

    const newQuery = query.toString();

    if (newQuery !== "") {
      window.history.replaceState({}, "", `${path}?${newQuery}`);
    } else {
      window.history.replaceState({}, "", path);
    }
  }

  if ($("body").hasClass("webshop-checkout")) {
    await initCheckout();
  }
})();

window.buyEventFnc = buyEventFnc;

async function buyEventFnc(event: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) {
  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();

  const form = event.target.closest("form");

  if (form === null) return;

  const buyProductResult = BuyProduct(form, "1", "0", "False");

  if (buyProductResult === false) return;

  const $form = $<HTMLFormElement>(form);

  localStorage.removeItem("ajaxAddedProduct");

  const productImage = $("body").hasClass("webshop-productinfo")
    ? $form.find('.imagescontainer .images img[itemprop="image"]').first().attr("src") ?? ""
    : $form.find(".image img").first().attr("src") ?? "";

  localStorage.setItem("lastAddedImage", productImage);

  const productName = $("body").hasClass("webshop-productinfo")
    ? $('h1 span[itemprop="name"]').text()
    : $form.find(".name a").text();

  localStorage.setItem("lastAddedName", productName);

  const productUrl = $("body").hasClass("webshop-productinfo")
    ? location.pathname
    : CurrencyReturnUrl.includes("?")
    ? `${CurrencyReturnUrl}&RefreshAllPrices=1`
    : `${CurrencyReturnUrl}?RefreshAllPrices=1`;

  localStorage.setItem("lastAddedUrl", productUrl);

  const productNumber = $form.find('input[name="ProductID"]').val()?.toString();

  const quantityVal = $form.find("input#amount").val();

  const quantity =
    quantityVal && typeof quantityVal === "string" ? (isNaN(parseInt(quantityVal)) ? 1 : parseInt(quantityVal)) : 1;

  if (productNumber === undefined) return;

  const extraProducts = $(".extraProduct.active")
    .toArray()
    .map((ep) => {
      const $ep = $(ep);

      const extraProductQuantityVal = $ep.find("input#amount").val();

      const extraProductQuantity =
        extraProductQuantityVal && typeof extraProductQuantityVal === "string"
          ? isNaN(parseInt(extraProductQuantityVal))
            ? 1
            : parseInt(extraProductQuantityVal)
          : 1;

      return {
        productNumber: $ep.data("id").toString(),
        qty: extraProductQuantity,
      };
    });

  const { newProducts, existingProducts } = extraProducts.concat({ productNumber, qty: quantity }).reduce<{
    newProducts: Array<{ productNumber: string; qty: number }>;
    existingProducts: Array<{ productNumber: string; qty: number }>;
  }>(
    (acc, cur) => {
      const tmp = basketProducts.find((bp) => bp.productNumber === cur.productNumber);

      if (tmp) {
        acc.existingProducts = [
          ...acc.existingProducts,
          {
            productNumber: cur.productNumber,
            qty: cur.qty + tmp.quantity,
          },
        ];

        return acc;
      }

      acc.newProducts = [...acc.newProducts, { productNumber: cur.productNumber, qty: cur.qty }];

      return acc;
    },
    { newProducts: [], existingProducts: [] }
  );

  const shippingProducts = (await getShippingProducts()).map<ShippingProduct>((p) => {
    const existingProduct = existingProducts.find((p) => p.productNumber === p.productNumber);

    return {
      productNumber: p.productNumber,
      qty: existingProduct ? existingProduct.qty + 1 : p.qty,
    };
  });

  if (newProducts.length > 0) {
    shippingProducts.push(...newProducts);
  }

  const returnBuyAdded = $("body").hasClass("webshop-productinfo")
    ? $form.find("#returnBuy").length > 0 &&
      $form.find("#returnBuy").is(":checked") &&
      basketProducts.some((p) => p.productNumber === "retur60") === false
    : $form.find("input.returnBuy").length > 0 &&
      $form.find("input.returnBuy").is(":checked") &&
      basketProducts.some((p) => p.productNumber === "retur60") === false;

  const workshopAdded = $("body").hasClass("webshop-productinfo")
    ? $form.find("#workshopAdd").length > 0 &&
      $form.find("#workshopAdd").is(":checked") &&
      basketProducts.some((p) => p.productNumber === "workshop") === false
    : $form.find("input.workshopAdd").length > 0 &&
      $form.find("input.workshopAdd").is(":checked") &&
      basketProducts.some((p) => p.productNumber === "workshop") === false;

  const extrasURL =
    returnBuyAdded && workshopAdded
      ? "/shop/showbasket.html?AddMultiple=1&ProductID=|retur60|workshop&Amount=|1|1"
      : returnBuyAdded
      ? "/shop/showbasket.html?AddMultiple=1&ProductID=|retur60&Amount=|1"
      : workshopAdded
      ? "/shop/showbasket.html?AddMultiple=1&ProductID=|workshop&Amount=|1"
      : "";

  const promises = [];

  if (extrasURL !== "") {
    promises.push(() => fetch(extrasURL));
  }

  if (shippingProducts.length > 0) {
    promises.push(async () => {
      const shipping = await getShippingPrices({
        sessionId: getSessionId(),
        products: shippingProducts,
        currency: siteCurrency,
        deliveryCountry: await getDeliveryCountry(),
        shippingName: siteShippingName,
      });
      getState().setShipping(shipping);

      return shipping;
    });
  }

  await pAll(promises);

  $form.trigger("submit", ["buy"]);
}

window.buttonBuyEventFnc = buttonBuyEventFnc;

async function buttonBuyEventFnc(
  event: JQuery.ClickEvent<HTMLButtonElement, undefined, HTMLButtonElement, HTMLButtonElement>
) {
  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();

  const $button = $(event.currentTarget);

  const buttonInitialContent = $button.html();

  const $spinner = `<i class="fa-solid fa-spinner-third fa-spin fa-2xl" style="--fa-primary-color: #ffffff;"></i>`;

  $button.css("filter", "opacity(0.7) grayscale(.2)").html($spinner);

  // const $productForm = $button.parents("form");

  const data = <ThuleBuyBtnData>$button.data();

  localStorage.removeItem("ajaxAddedProduct"); /* ??? */

  // const productImage = data.productImage || $productForm.find(".CustomSpecifications img.box-img").attr("src") || "";

  localStorage.setItem("lastAddedImage", data.productImage);

  // const productName = $("body").hasClass("webshop-productinfo")
  //   ? $('h1 span[itemprop="name"]').text()
  //   : $form.find(".name a").text();

  localStorage.setItem("lastAddedName", data.productName);

  const productUrl = location.pathname;

  localStorage.setItem("lastAddedUrl", productUrl);

  // const productNumber = $form.find('input[name="ProductID"]').val()?.toString();

  // const quantityVal = $form.find("input#amount").val();

  // const quantity =
  //   quantityVal && typeof quantityVal === "string"
  //     ? isNaN(parseInt(quantityVal))
  //       ? 1
  //       : parseInt(quantityVal)
  //     : 1;

  // if (productNumber === undefined) return;

  // const extraProducts = $(".extraProduct.active")
  //   .toArray()
  //   .map((ep) => {
  //     const $ep = $(ep);

  //     const extraProductQuantityVal = $ep.find("input#amount").val();

  //     const extraProductQuantity =
  //       extraProductQuantityVal && typeof extraProductQuantityVal === "string"
  //         ? isNaN(parseInt(extraProductQuantityVal))
  //           ? 1
  //           : parseInt(extraProductQuantityVal)
  //         : 1;

  //     return {
  //       productNumber: $ep.data("id").toString(),
  //       qty: extraProductQuantity,
  //     };
  //   });

  const skus = data.returnBuy ? [...data.skus, "retur60"] : data.skus;

  const products = skus.map((sku) => ({
    productNumber: sku,
    qty: 1,
  }));

  const { newProducts, existingProducts } = products.reduce<{
    newProducts: Array<{ productNumber: string; qty: number }>;
    existingProducts: Array<{ productNumber: string; qty: number }>;
  }>(
    (acc, cur) => {
      const tmp = basketProducts.find((bp) => bp.productNumber === cur.productNumber);

      if (tmp) {
        acc.existingProducts = [
          ...acc.existingProducts,
          {
            productNumber: cur.productNumber,
            qty: cur.qty + tmp.quantity,
          },
        ];

        return acc;
      }

      acc.newProducts = [...acc.newProducts, { productNumber: cur.productNumber, qty: cur.qty }];

      return acc;
    },
    { newProducts: [], existingProducts: [] }
  );

  const shippingProducts = (await getShippingProducts()).map<ShippingProduct>((p) => {
    const existingProduct = existingProducts.find((p) => p.productNumber === p.productNumber);

    return {
      productNumber: p.productNumber,
      qty: existingProduct ? existingProduct.qty + 1 : p.qty,
    };
  });

  if (newProducts.length > 0) {
    shippingProducts.push(...newProducts);
  }

  const promises = [];

  if (products.length > 0) {
    const ProductIDs = products.reduce<string>((acc, cur) => {
      return `${acc}|${cur.productNumber}`;
    }, "");

    const Amounts = products.reduce<string>((acc, cur) => {
      return `${acc}|${cur.qty}`;
    }, "");

    const url = `/shop/showbasket.html?AddMultiple=1&ProductID=${ProductIDs}&Amount=${Amounts}&RefreshAllPrices=1`;

    promises.push(() => fetch(url));
  }

  if (shippingProducts.length > 0) {
    promises.push(async () => {
      const shipping = await getShippingPrices({
        sessionId: getSessionId(),
        products: shippingProducts,
        currency: siteCurrency,
        deliveryCountry: await getDeliveryCountry(),
        shippingName: siteShippingName,
      });
      getState().setShipping(shipping);

      return shipping;
    });
  }

  const results = await pAll(promises);

  if (results.length == 0) {
    console.error("Fuck");
    return;
  }

  await pAll([triggerInstantAddedToBasketPopup, refreshBasketProducts]);

  $button.css("filter", "").html(buttonInitialContent);
}
