import Cookies from "js-cookie";
import ky from "ky";
import { nanoid } from "nanoid";
import type {
  CombinedSessionValues,
  CountryDetailsResponse,
  GetSessionValues,
  SessionValuesResponse,
} from "../types/global";
import { setState } from "./store";

export const getSessionValues: GetSessionValues = async (force?: boolean) => {
  // console.log('Getting session values');

  const localStorageSessionValues = localStorage.getItem("sessionValues");

  if (localStorageSessionValues !== null && !force) {
    // console.log('Returning session values from localStorage');

    return JSON.parse(localStorageSessionValues) as CombinedSessionValues;
  }

  // console.log('Returning session values from API');

  const sessionData = await ky
    .get("/shop/ajaxwrapper.asp?fc=getsessionvaluesJSON", {
      hooks: {
        afterResponse: [
          async (request, _, response) => {
            if (response.redirected) {
              return await ky.get(request.url, { fetch: window.kyFetch });
            }

            return response;
          },
        ],
      },
      fetch: window.kyFetch,
    })
    .json<SessionValuesResponse>();

  const countryData = await ky
    .get(`/shop/ajaxwrapper.asp?fc=getcountrydetails&countryid=${sessionData.countryId}`, {
      hooks: {
        afterResponse: [
          async (request, _, response) => {
            if (response.redirected) {
              return await ky.get(request.url, { fetch: window.kyFetch });
            }

            return response;
          },
        ],
      },
      fetch: window.kyFetch,
    })
    .json<CountryDetailsResponse>();

  const combinedSessionValues: CombinedSessionValues = {
    ...sessionData,
    countryDetails: countryData,
  };

  localStorage.setItem("sessionValues", JSON.stringify(combinedSessionValues));

  setState((state) => {
    state.session = combinedSessionValues;
  });

  return combinedSessionValues;
};

window.getSessionValues = getSessionValues;

export const getSessionId = () => {
  // return (await getSessionValues()).uniqueSessionIdentifier;
  const cookieId = Cookies.get("ds_cookie_id");

  return !!cookieId && cookieId !== "undefined" ? cookieId : nanoid();
};

export const getDeliveryCountry = async (force?: boolean) => {
  return (await getSessionValues(force)).countryDetails.countryCode;
};

export type GetDeliveryCountry = typeof getDeliveryCountry;

window.getDeliveryCountry = getDeliveryCountry;

export const refreshBasketProducts = async () => {
  const newBasket = await ky.get("/shop/basket.asp?RefreshAllPrices=1", { fetch: window.kyFetch }).text();

  $("#basket").replaceWith($(newBasket));
};

export type RefreshBasketProducts = typeof refreshBasketProducts;

window.refreshBasketProducts = refreshBasketProducts;

export const refreshShowBasket = async () => {
  if ($("#showbasketContent").length < 1 || !location.pathname.includes("showbasket.html")) return;

  const newShowBasket = await ky.get("/shop/showbasket.asp?RefreshAllPrices=1", { fetch: window.kyFetch }).text();

  $("#showbasketContent").replaceWith($(`<div>${newShowBasket}</div>`).find("#showbasketContent"));
};

export type RefreshShowBasket = typeof refreshShowBasket;

window.refreshShowBasket = refreshShowBasket;
